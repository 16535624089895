input {
    border: 2px solid #aaa;
    border-radius: 4px;
    margin: 8px;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    transition: .3s;
    width: 100%;
}
  
input:focus {
    border-color: dodgerBlue;
    box-shadow:0 0 8px 0 dodgerBlue;
}

.input-container {
    flex-grow: 1;
}

label {
    margin: 16px 0 0 0;
}

form {
    display: flex;
}